import React, { type FC } from 'react';
import styles from './button.scss';
import { Text } from '@wix/wix-base-ui';
import { cx } from '@/util';

import type { ButtonProps } from './buttonTypes';

const Button: FC<ButtonProps> = ({
  value,
  label,
  onClick,
  selected,
  shouldTranslate,
}) => {
  return (
    <div
      onClick={() => {
        onClick(value);
      }}
      className={cx(styles.button, { [styles.button_selected]: selected })}
    >
      <Text
        size="tiny"
        shouldTranslate={shouldTranslate}
        skin={selected ? 'link' : 'standard'}
      >
        {label || value}
      </Text>
    </div>
  );
};

export default Button;
