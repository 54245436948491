import React, { memo, type FC, useState, useEffect, useRef } from 'react';
import { Search } from '@wix/wix-base-ui';
import { hoc } from '@/util';

import type { ISuggestResult, ContentManager } from '@/presetApi';
import type { BusinessType } from '@/siteGlobalData';

import { getBusinessTypeByString, suggestItemsToOptions } from './utils';
import {
  type DispatchProps,
  mapDispatchToProps,
} from './businessTypeInputMapper';

export interface OwnProps {
  value: BusinessType;
  placeholder: string;
  className?: string;
  clearButton?: boolean;
  onChange: any;
  onBlur?: any;
  onFocus?: any;
}

export interface BusinessTypeInputProps extends OwnProps, DispatchProps {}

const BusinessTypeInput: FC<BusinessTypeInputProps> = memo(
  function BusinessTypeInput({
    value,
    placeholder,
    className,
    clearButton,
    getContentManager,
    initiateContentManager,
    onChange,
    onBlur,
    onFocus,
  }) {
    const contentManager = useRef<ContentManager>(getContentManager());
    const request = useRef<Promise<void>>();

    const [inputSuggestions, setInputSuggestions] = useState<
      Record<string, ISuggestResult>
    >({});

    useEffect(() => {
      const initializeContentManager = async () => {
        if (contentManager.current === undefined) {
          contentManager.current = null;
          await initiateContentManager();
          contentManager.current = getContentManager();
        }
      };

      initializeContentManager();
    }, [getContentManager, initiateContentManager]);

    useEffect(() => {
      const inputValue = value?.displayName;
      if (!inputSuggestions[inputValue]) {
        const promise = contentManager.current
          ?.suggest(inputValue)
          ?.then((suggestions: ISuggestResult) => {
            if (request.current === promise && inputValue) {
              setInputSuggestions((state) => ({
                ...state,
                [inputValue]: suggestions,
              }));
            }
          });
        request.current = promise;
      }
    }, [value, inputSuggestions]);

    const handleSuggest = (inputValue: string) => {
      const businessType = getBusinessTypeByString(
        inputValue,
        inputSuggestions[inputValue],
      );

      onChange(businessType);
    };

    const handleChange = (inputValue: string) => {
      const businessType = getBusinessTypeByString(
        inputValue,
        inputSuggestions[inputValue],
      );

      onChange(businessType);
    };

    return (
      <Search
        automationId="ai-injection-into-template-business-type"
        className={className}
        value={value?.displayName}
        options={suggestItemsToOptions(inputSuggestions[value?.displayName])}
        onClear={onChange}
        clearButton={clearButton}
        placeholder={placeholder}
        onChange={handleChange}
        onEnter={handleSuggest}
        onItemClick={handleSuggest}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    );
  },
);

export default hoc.connect(
  hoc.STORES.EDITOR_API,
  null,
  mapDispatchToProps,
)(BusinessTypeInput);
