import React, { type FC, useState, useEffect } from 'react';
import { translate } from '@/i18n';
import Button from './components/button';
import styles from './suggestion.scss';
import { TextButton } from '@wix/wix-base-ui';

import Dropdown from './components/dropdown';

import type { SuggestionTypes } from './suggestionTypes';
import type { Option } from './components/dropdown/dropdownTypes';

const Suggestion: FC<SuggestionTypes> = ({
  controls,
  length,
  onSelect,
  selectedValue,
  shouldTranslate = true,
  onSeeMoreClick,
  onSeeLessClick,
  onDropdownOpen,
  onDropdownToggle,
}) => {
  const [showAll, setShowAll] = useState<boolean | null>(null);

  const handleSeeOrLessMoreClick = () => {
    setShowAll(!showAll);
  };

  useEffect(() => {
    if (showAll === true && onSeeMoreClick) {
      onSeeMoreClick();
    } else if (showAll === false && onSeeLessClick) onSeeLessClick();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAll]);

  const withLimit = length !== undefined;
  const controlsLocal =
    showAll && withLimit ? controls : controls.slice(0, length);

  return (
    <div className={styles.suggestion}>
      {controlsLocal.map((control) => {
        if (control.type === 'button') {
          return (
            <Button
              selected={selectedValue === control.value}
              value={control.value}
              label={control.label}
              onClick={(value: string) => {
                onSelect({
                  label: control.label,
                  type: control.type,
                  value,
                });
              }}
              shouldTranslate={shouldTranslate}
            />
          );
        } else if (control.type === 'dropdown') {
          return (
            <Dropdown
              label={control.label}
              options={control.options}
              onSelect={(value: Option) => {
                onSelect({
                  label: control.label,
                  type: control.type,
                  value,
                });
              }}
              shouldTranslate={shouldTranslate}
              onDropdownOpen={onDropdownOpen}
              onDropdownToggle={onDropdownToggle}
            />
          );
        }

        return null;
      })}

      {withLimit && (
        <div className={styles.suggestion__moreButton}>
          <TextButton
            shouldTranslate={false}
            size="tiny"
            skin="standard"
            underline="none"
            onClick={handleSeeOrLessMoreClick}
          >
            {translate(
              showAll
                ? 'ai_text_creator_chat_suggestions_see_less'
                : 'ai_text_creator_chat_suggestions_see_more',
            )}
          </TextButton>
        </div>
      )}
    </div>
  );
};

export default Suggestion;
